import { SubscriptionModel, ProjectModel, UserModel, VoyageModel } from "./../../../models";

export default class HttpParseUtility {

  static getPayload(httpResponse: any): any {
    let json: any
    try {
      // is the response an object or a string?
      if (typeof(httpResponse) !== 'object') {
        // deserialize string to object
        httpResponse = JSON.parse(httpResponse);
      }
      json = httpResponse
      // is the payload property 'body' or 'data'?
      if ('body' in json) {
        json = json['body']
      }
      else if ('data' in json) {
        json = json['data']
      }
      // check if lambda response contains the 'Attributes', 'Item', or 'Items' property
      if ('Item' in json) {
        json = json['Item']
      }
      else if ('Items' in json) {
        json = json['Items']
      }
      else if ('Attributes' in json) {
        json = json['Attributes']
      }
      // check the return type
      if (typeof(json) !== 'object') {
        json = JSON.parse(json)
      }
      return json
    }
    catch(error) {
      throw new Error(error)
    }
  }

  //#region parseProject() HELPERS
  static getTaskCount(payload: any): number {
    try {
      const key: string = 'taskCount'
      return this.getNumber(payload, key)
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getVoyageCount(payload: any): number {
    try{
      const key: string = 'voyageCount'
      return this.getNumber(payload, key)
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getDaysUntilTaskDeletion(payload: any): number {
    try{
      const key: string = 'daysUntilTaskDeletion'
      const res: number = this.getNumber(payload, key)
      // setting daysUntilTaskDeletion to zero could be dangerous
      return res !== 0 ? res : -1
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getTags(payload: any): string[] {
    try {
      const key: string = 'tags'
      return key in payload ? this.getTagList(payload[key]) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getTagList(data: any): string[] {
    try {
      switch (typeof(data)) {
        case 'string':
          console.log('string')
          return this.getEvalReplacement(data)
        case 'object':
          console.log('object')
          return data
        default:
          let errorMsg = 'Payload data type ' + typeof(data) + ' unrecognized!'
          throw new Error(errorMsg)
      }
    }
    catch(e) {
      console.error(e)
      throw e
    }
  }
  
  static removeNull(array: string[]): string[] {
    return array.filter(x => x !== null)
  };

  static areVoyagesEnabled(payload: any): boolean {
    try {
      const key: string = 'voyageEnabled'
      return this.getBooleanFromString(payload[key])
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getBooleanFromString(element): boolean {
    if (element === "True" || element === "true")
      return true
    return false
  }

  static getVoyages(payload: any): VoyageModel[] {
    try {
      const key: string = 'voyages'
      return key in payload ? this.getVoyageList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getLatestVoyage(payload: any): string {
    try {
      const keys: string[] = ['latestVoyageUuid', 'latestVoyage']
      let res = ''
      while(keys.length > 0 && res === '') {
        res = this.getString(payload, keys.pop())
      }
      return res !== '' ? res : ''
      
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getAutoVoyageLength(payload: any): number {
    try {
      const key: string = 'voyageLength'
      const res: number = this.getNumber(payload, key)
      return res !== 0 ? res : 2
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getDeckHands(payload: any): string[] {
    try {
      const key: string = 'deckHands'
      return key in payload ? this.getList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getFirstMates(payload): string[] {
    try{
      const key: string = 'firstMates'
      return key in payload ? this.getList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getCaptains(payload):  string[] {
    try {
      const key: string = 'captains'
      return key in payload ? this.getList(payload, key) : []
    }
    catch(e) {
      console.error(e)
      throw new Error(e)
    }
  }

  static getVoyageList(data: any, k: string): VoyageModel[] {
    if (Array.isArray(data)) {
      throw new Error('Unrecognized data: array')
    }
    let voyages: VoyageModel[] = []
    let voyagesObj: Object = data[k]
    let keys = Object.keys(voyagesObj)
    let values = Object.values(voyagesObj)
    keys.forEach((key, index) => {
      voyages.push(this.parseVoyage(key, values, index))
    })
    return voyages
  }

  static parseVoyage(key, values, index): VoyageModel {
    try {
      return new VoyageModel({
        uuid: key,
        name: values[index]?.voyageName,
        description: values[index]?.voyageGoal,
        startTime: values[index]?.startTime,
        endTime: values[index]?.endTime,
        viewId: values[index]?.viewId,
        lengthInWeeks: values[index]?.lengthInWeeks,
        status: values[index]?.status
      })
    }
    catch(e) {
      throw e
    }
  }
  //#endregion

  //#region SHARED HELPERS
  static getNumber(data: any, k: string): number {
    return k in data ? parseInt(data[k]) : 0
  }

  static getString(data: any, k: string) {
    return k in data ? data[k] : ''
  }

  static getList(data: any, k: string): any[] {
    try {
      switch (typeof(data[k])) {
        case 'string':
          console.log('string')
          return this.getEvalReplacement(data[k])
        case 'object':
          console.log('object')
          return data[k]
        default:
          let errorMsg = 'Payload data type ' + typeof(data[k]) + ' unrecognized!'
          throw new Error(errorMsg)
      }
    }
    catch(e) {
      console.error(e)
      throw e
    }
  }

  static getEvalReplacement(input: string): string[] {
    // Heavy Replacement Strategy.
    // const input = `['la maison', "l'animal"]` // Input.
    const array = input
      .replace(/^\[|\]$/g, '') // Remove leading and ending square brackets ([]).
      .split(',') // Split by comma.
      .map((phrase) => // Iterate over each phrase.
        phrase.trim() // Remove leading and ending whitespace.
        .replace(/"/g, '') // Remove all double quotes (").
        .replace(/^\'|\'$/g, '') // Remove leading and ending single quotes (').
      )
    console.log(array) // Proof.
    return array
  }

  static getListFromString(input: string) {
    // String.prototype.replace() + JSON.parse() Strategy.
    console.log(input)
    let replacement = input.replace(/'/g, '"')
    console.log(replacement)
    const array = JSON.parse(replacement) // Array.
    console.log(array) // Proof.
    return array
  }
  //#endregion
}
