import { Injectable, Type } from '@angular/core';
import { HttpService } from './../http'
import { TribeFilterPipe } from './../pipes';
import { UserModel } from './../../models';
import util from './../http/utilities/http-parse-utility'
import UserParser from './../services/utilities/user-parser'

@Injectable({
  providedIn: 'root'
})
export class TribeService {
  public users: UserModel[];
  public tribeMap: object;
  public lastVisitedProjectUuid: string;
  private tribeFilter: TribeFilterPipe = new TribeFilterPipe()

  constructor(private http: HttpService) {}

  getTribe(projectUuid: string): Promise<UserModel[]> {
    return new Promise<UserModel[]>((resolve, reject) => {
      if (!!(this.users) && projectUuid === this.lastVisitedProjectUuid) {
        console.log('getting tribe from cache');
        resolve(this.users);
      }
      else {
        console.log('getting tribe from api');
        this.http.pullTribe(projectUuid).toPromise()
        .then((res) => {
          let users = [
            new UserModel({
              uuid: 'unassigned', 
              email: 'unassigned', 
              firstname: 'unassigned', 
              lastname: 'unassigned'
            })
          ];
          this.lastVisitedProjectUuid = projectUuid;
          let incomingUsers: UserModel[] = this.pullHandler(res); 
          this.users = users.concat(incomingUsers);
          this.tribeMap = this.buildTribeMap()
          resolve(this.users)
        })
        .catch((err) => {
          console.log('Error: ' + JSON.stringify(err));
          reject(err);
        })
      }
    })
  }

  getTribeMember(userUuid: string): UserModel {
    if (this.tribeMap) {
      return this.tribeMap[userUuid]
    }
    const idx = this.users.findIndex((user) => {
      return user.uuid === userUuid
    })
    return idx > -1 ? this.users[idx] : new UserModel({
      uuid: 'unassigned', 
      email: 'unassigned', 
      firstname: 'unassigned', 
      lastname: 'unassigned'
    })
  }

  buildTribeMap(): object {
    let tribeMap = {}
    this.users.forEach((user) => {
      tribeMap[user.uuid] = user
    })
    return tribeMap
  }

  getFilteredMembers(filterBy: string[]) {
    let members = this.tribeFilter.transform(this.users, filterBy)
    console.log(members);
    return members
  }

  private pullHandler(res): UserModel[]
  {
    console.log("tribe pull handler...")
    let incomingUsers: UserModel[] = [];
    let jsonBody = util.getPayload(res)
    jsonBody.forEach(jsonStr => {

      let element = JSON.parse(jsonStr)
      let user = this.parseTribeMember(element)
      incomingUsers.push(user)

    });
    return incomingUsers
  }

  private parseUser(payload: any): UserModel {
    return UserParser.parseUser(payload)
  }

  private parseTribeMember(payload: any): UserModel {
    return UserParser.parseTribeMember(payload)
  }

}

