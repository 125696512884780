import { EventEmitter } from '@angular/core';
import { Injectable, Type } from '@angular/core';
import { TaskModel } from './../../models/TaskModel';
import taskUtil from './utilities/task-parser'
import httpUtil from './../http/utilities/http-parse-utility'
import { NotificationService } from './notification.service';
import { HttpService } from '../http/http.service';
import { ProjectModel } from '../../models';
import { AuthService } from '../auth/auth.service';
import { QuestionBase } from 'src/app/models/question/question-base.model';
import { Observable, of } from 'rxjs';
import { DropdownQuestion } from 'src/app/models/question/question-dropdown.model';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  public tasks: TaskModel[] = [];
  public taskAdded: EventEmitter<TaskModel> = new EventEmitter();
  public tasksReturned: EventEmitter<TaskModel[]> = new EventEmitter();
  public lastVisitedProjectUuid: string;

  constructor(private httpService: HttpService,
    private notification: NotificationService,
    private auth: AuthService) {
      this.auth.userLoggedOut.subscribe(() => {
        this.tasks.length = 0;
      })
    }

  getTasks(projectParam: string): Promise<TaskModel[]> {
    return new Promise<TaskModel[]>((resolve, reject) => {
      if (this.tasks.length !== 0 && projectParam === this.lastVisitedProjectUuid) {
        console.log('getting tasks from cache');
        resolve(this.tasks);
      }
      else {
        console.log('getting tasks from api');
        this.httpService.pullTasks(projectParam).toPromise()
        .then((res) => {
          let incomingTasks: TaskModel[] = this.pullHandler(res); 
          this.lastVisitedProjectUuid = projectParam;
          // console.log('incomingTasks', incomingTasks)
          this.tasks = incomingTasks;
          resolve(incomingTasks);
        })
        .catch((err) => {
          console.log('Error: ' + JSON.stringify(err));
          reject(err);
        })
      }
    })
  }

  getTasksLazy(project: ProjectModel, numberOfTasks: number, lastEvaluatedKey: string): Promise<TaskModel[]> {
    console.log("getting tasks");
    return new Promise<TaskModel[]>((resolve, reject) => {
      const uuid = project.uuid
      if (this.tasks.length !== 0 && uuid === this.lastVisitedProjectUuid && lastEvaluatedKey === null) {
        console.log('from cache');
        resolve(this.tasks);
      }
      else {
        console.log('from api lazy');
        this.httpService.pullTasksLazy(project, numberOfTasks, lastEvaluatedKey).toPromise()
        .then((res) => {
          let incomingTasks: TaskModel[] = this.pullHandler(res);
          if(uuid !== this.lastVisitedProjectUuid)
          {
            this.tasks = incomingTasks;
          }
          else
          {
            this.tasks = this.tasks.concat(incomingTasks);
          }
          this.tasksReturned.emit(this.tasks);
          this.lastVisitedProjectUuid = uuid;
          resolve(incomingTasks);

          console.log(res)
          let key = this.getLastEvaluatedKey(res)
          if (key)
          {
            this.getTasksLazy(project, 100, key['taskUuid']); //recursive call
          } 
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        })
      }
    })
  }

  getLastEvaluatedKey(res: any): string {
    let lastEvaluatedKey: string;
    let data: any
    // does the res have the 'data' property?
    if (!('data' in res)) {
      return undefined
    }
    data = res['data']
    // does the data have the 'LastEvaluatedKey' property?
    if (!('LastEvaluatedKey' in data)) {
      return undefined
    }
    lastEvaluatedKey = data['LastEvaluatedKey']
    return lastEvaluatedKey
  }

  pullHandler(res: any): TaskModel[] {
    let tasks: TaskModel[] = []
    let items = Array.from(httpUtil.getPayload(res))
    items.forEach((payload) => {
      tasks.push(taskUtil.parseTask(payload))
    })
    console.log('tasks', tasks)
    return tasks
  }

  getQuestions(payload: any): Observable<QuestionBase<string>[]> {
    const questions: QuestionBase<string>[] = [
      new DropdownQuestion({
        key: 'brave',
        label: 'Bravery Rating',
        options: [
          {key: 'solid',  value: 'Solid'},
          {key: 'great',  value: 'Great'},
          {key: 'good',   value: 'Good'},
          {key: 'unproven', value: 'Unproven'}
        ],
        order: 3
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  updateTask(updatedTask: TaskModel, taskIndex: number, project: ProjectModel): Promise<TaskModel>
  {
    return new Promise<TaskModel>((resolve, reject) => {
      this.notification.showInProgress("Waiting on response...", "Updating task " + updatedTask.title);
      this.httpService.updateTask(updatedTask, project).toPromise()
      .then((res) => {
        if(res != undefined)
        {
          console.log("Updated task successfully!");
          this.notification.showSuccess("Task updated!", updatedTask.title);
          this.tasks[taskIndex] = new TaskModel(updatedTask);
          resolve(updatedTask)
        }
        else
        {
          const msg = "Task has been updated by another user, please refresh!"
          console.log(msg);
          this.notification.showError(msg, updatedTask.title, {
            timeOut: 10000
          });
          resolve(updatedTask)
        }
        
      })
      .catch((err) => {
        console.log("Error: " + err);
        this.notification.showError("Task update failed!", updatedTask.title);
        reject(err)
      })
    })
  }

  pushTask(task: TaskModel, project: ProjectModel): Promise<TaskModel> {
    return new Promise<TaskModel>((resolve, reject) => {
      this.notification.showInProgress("Waiting on response...", "Setting up task: " + task.title);
      this.httpService.pushTask(task, project).toPromise()
      .then((res) => {
        let newTask = this.pushTaskResponseHandler(task, res);
        this.notification.showSuccess("Task created!", newTask.title);
        this.tasks.push(newTask);
        resolve(newTask);
      })
      .catch((err) => {
        console.log("Error: " + err);
        this.notification.showError("Task creation failed!", task.title);
        reject(err);
      })
    })
  }

  pushTaskResponseHandler(partialTask: TaskModel, res: any): TaskModel {
    
    const payload = httpUtil.getPayload(res)
    const task = taskUtil.parseTask(payload)
    // const newTask = this.mergeIncompleteTasks(partialTask, payload)
    partialTask.mergeIncompleteTask(task)
    return partialTask;
  }

  mergeIncompleteTasks(baseTask: TaskModel, payload: any): TaskModel {
    try {
      console.log("payload", payload)
      const partialTask = taskUtil.parseTask(payload)
      console.log('partialTask', partialTask)
      console.log('baseTask', baseTask)

      const mergeResult = new TaskModel({
        uuid: partialTask.uuid,
        viewId: partialTask.viewId,
        creationDate: partialTask.creationDate,
        lastUpdateTime: partialTask.creationDate,
        latestUpdate: partialTask.latestUpdate,
        changedBy: partialTask.changedBy,
        title: baseTask.title,
        description: baseTask.description,
        status: baseTask.status,
        assignee: baseTask.assignee, 
        sharkRank: baseTask.sharkRank,
        sharkClassification: baseTask.sharkClassification,
        history: baseTask.history,
        tags: baseTask.tags,
        questions: baseTask.questions
      })
      return mergeResult
    }
    catch(e) {
      console.error(e)
      throw e
    }
  }
}
